<template>
  <div>
    <content-card title="Imagenes" v-loading.fullscreen.lock="loading">
      <div class="flex flex-col">
        <div class="my-2">
          <input type="file" v-if="!maxImagesLimitReached" @change="imageUpload" accept="image/*" />
          <div v-for="image of images" :key="image" class="relative">
            <sa-icon-button
              @click="deleteImage(image)"
              iconClass="text-4xl bx bx-minus-circle text-red-500 absolute top-5 right-1"
            />
            <img :src="getImageSrc(image)" alt="img" width="300px" />
          </div>
        </div>
      </div>
    </content-card>
  </div>
</template>
<script>
import { state, mutations } from '../store';
import FileService from '@/services/FileService';
import error from '@/mixins/error';
import _ from 'lodash';

export default {
  name: 'ImagesBlock',
  mixins: [error],
  components: {
    ContentCard: () => import('@/components/molecules/ContentCard'),
  },
  data() {
    return {
      loading: false,
      errorMessage: undefined,
    }
  },
  computed: {
    images() {
      return state.order.images;
    },
    maxImagesLimitReached() {
      return this.images.length >= 3;
    },
  },
  watch: {
    'errorMessage': {
      deep: true,
      handler: _.debounce(async function () {
        if (this.errorMessage) {
          await this.$toastr.e(this.errorMessage);
          this.errorMessage = undefined;
        }
      }, 300)
    },
  },
  methods: {
    getImageSrc(image) {
      return `${process.env.VUE_APP_S3_BUCKET}/${process.env.VUE_APP_DOCUMENTS_SAMII}/${image}`;
    },
    async imageUpload(imgFile) {
      if (imgFile?.target?.files?.length) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(imgFile.target.files[0]);
        fileReader.onload = async () => {
          await this.createImage(imgFile.target.files[0]);
        };
      }
    },
    async createImage(image) {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('File', image);
        formData.append('Type', 1);
        const image_path = await FileService.create(formData);
        mutations.pushImage(image_path);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }

    },
    async deleteImage(imageSrc) {
      this.loading = true;
      try {
        const data = { files: [imageSrc] };
        await FileService.delete(data);
        mutations.filterImage(imageSrc);
      } catch (e) {
        this.errorMessage = this.getErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  }
}
</script>
